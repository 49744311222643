module.exports = {
  "crm-record-access-management-lib-interaction": {
    "name": "record-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "propertyName": {
        "type": "string",
        "isOptional": true
      },
      "isHubspotDefined": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "crm-record-access-management-lib",
    "version": "1"
  },
  "enrollButtonClick": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "name": "string",
      "objectTypeId": "string"
    },
    "namespace": "automation-ui-crm-card-workflows-invoker",
    "version": "1"
  },
  "enrollFailureMessage": {
    "name": "view",
    "class": "view",
    "properties": {
      "errorKey": "string",
      "name": "string",
      "objectTypeId": "string"
    },
    "namespace": "automation-ui-crm-card-workflows-invoker",
    "version": "1"
  },
  "nextActivityColumnInteraction": {
    "name": "Interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clickToDoTask",
        "clickCallTask",
        "clickEmailTask",
        "clickScheduleAMeeting"
      ]
    },
    "namespace": "guided-selling-stage-tracker",
    "version": "1"
  },
  "nextActivitySave": {
    "name": "Interaction",
    "class": "usage",
    "properties": {
      "action": [
        "save"
      ],
      "type": {
        "type": "string"
      }
    },
    "namespace": "guided-selling-stage-tracker",
    "version": "1"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": "string",
      "actionMeta": "string"
    },
    "namespace": "buyer-intent-card",
    "version": "1"
  },
  "pageView": {
    "name": "pageView",
    "class": "view",
    "properties": {
      "screen": "string",
      "actionMeta": "string"
    },
    "namespace": "buyer-intent-card",
    "version": "1"
  },
  "feedbackDropdown": {
    "name": "Interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": [
        "helpful",
        "notHelpful"
      ]
    },
    "namespace": "customer-success-contacts-card-lib",
    "version": "1"
  },
  "click-team-summary-open-tickets-link": {
    "properties": {
      "action": [
        "click team summary open tickets link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-team-summary-closed-tickets-link": {
    "properties": {
      "action": [
        "click team summary closed tickets link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-team-summary-time-to-close-link": {
    "properties": {
      "action": [
        "click team summary time to close link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-team-summary-time-to-first-agent-reply-link": {
    "properties": {
      "action": [
        "click team summary time to first agent reply link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-team-summary-ces-link": {
    "properties": {
      "action": [
        "click team summary ces link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-team-summary-setup-ces-link": {
    "properties": {
      "action": [
        "click team summary setup ces link"
      ],
      "subscreen": [
        "team-summary"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-agent-availability-user-status-filter-option": {
    "properties": {
      "action": [
        "click agent availability user status filter option"
      ],
      "value": [
        "ALL_USERS",
        "AVAILABLE_ONLY",
        "AWAY_ONLY"
      ],
      "subscreen": [
        "agent-availability"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-agent-availability-user-link": {
    "properties": {
      "action": [
        "click agent availability user link"
      ],
      "subscreen": [
        "agent-availability"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-agent-availability-empty-state-cta": {
    "properties": {
      "action": [
        "click agent availability empty state cta"
      ],
      "subscreen": [
        "agent-availability"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-sla-progress-sla-type-filter-option": {
    "properties": {
      "action": [
        "click sla progress sla type filter option"
      ],
      "value": [
        "TIME_TO_CLOSE",
        "TIME_TO_FIRST_RESPONSE",
        "TIME_TO_NEXT_RESPONSE",
        "COMPLETED"
      ],
      "subscreen": [
        "sla-progress"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-sla-progress-empty-state-cta": {
    "properties": {
      "action": [
        "click sla progress empty state cta"
      ],
      "subscreen": [
        "sla-progress"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-sla-progress-off-state-cta": {
    "properties": {
      "action": [
        "click sla progress off state cta"
      ],
      "subscreen": [
        "sla-progress"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-todays-tickets-empty-state-connect-channel": {
    "properties": {
      "action": [
        "click todays tickets empty state connect channel"
      ],
      "subscreen": [
        "todays-tickets"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-todays-tickets-empty-state-move-channel": {
    "properties": {
      "action": [
        "click todays tickets empty state move channel"
      ],
      "subscreen": [
        "todays-tickets"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-calling-performance-calls-completed-link": {
    "properties": {
      "action": [
        "click calling performance calls completed link"
      ],
      "subscreen": [
        "calling-performance"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-calling-performance-calls-missed-link": {
    "properties": {
      "action": [
        "click calling performance calls missed link"
      ],
      "subscreen": [
        "calling-performance"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-calling-performance-call-duration-link": {
    "properties": {
      "action": [
        "click calling performance call duration link"
      ],
      "subscreen": [
        "calling-performance"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "click-calling-performance-empty-state-cta": {
    "properties": {
      "action": [
        "click calling performance empty state cta"
      ],
      "subscreen": [
        "calling-performance"
      ],
      "screen": [
        "summary"
      ]
    },
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotPreviewPageView": {
    "name": "copilot-preview-view",
    "class": "view",
    "properties": {
      "action": {
        "type": [
          "page_view"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewPrimaryAction": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "primary_action_click",
        "primary_action_auto_generated"
      ],
      "command": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewClosePreview": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "close_preview"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewChatWithChatSpot": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "ask_a_question"
      ],
      "followUpMessage": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewRefreshSummary": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "refresh_summary"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "copilotPreviewInteraction": {
    "name": "copilot-preview-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "dont_show_again",
        "settings_link_click"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-addon-copilot-preview",
    "version": "1"
  },
  "crmCardInteraction": {
    "namespace": "crm-cards-sdk",
    "name": "crm card interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "cardType": {
        "type": "string"
      },
      "cardId": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string"
      }
    }
  },
  "pipelineApprovalsSidebarInteraction": {
    "name": "sidebar-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "sourceApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm-pipeline-approvals-lib"
  },
  "adobeSignCardInteraction": {
    "name": "AdobeSign Card interactions",
    "class": "interaction",
    "properties": {
      "objectTypeId": "string",
      "action": [
        "AGREEMENT_EDITOR_OPEN_CLICK",
        "ADD_FROM_EXISTING_CLICK",
        "EDIT_DRAFT_CLICK",
        "DOWNLOAD_PDF_CLICK",
        "IMPORT_SIGNED_DOCUMENTS_CLICK",
        "SHOW_AGREEMENT_HISTORY_CLICK",
        "ADD_FROM_TEMPLATE_CLICK",
        "FILTER_CHANGE",
        "EDIT_AGREEMENT_CLICK",
        "REMOVE_AGREEMENT_CLICK",
        "RECONNECT_BUTTON_CLICK"
      ]
    },
    "namespace": "adobe-sign-cards-lib"
  },
  "adobeSignAgreementEditorInteraction": {
    "name": "AdobeSign Agreement Editor interactions",
    "class": "interaction",
    "properties": {
      "action": [
        "TAB_CLICK",
        "NEXT_CLICK",
        "SAVE_CLICK",
        "SEND_AGREEMENT_CLICK",
        "EXIT_CLICK",
        "ADD_DOCUMENT_CLICK",
        "REMOVE_DOCUMENT_CLICK",
        "MORE_INFORMATION_CLICK",
        "USE_SUGGESTION_CLICK",
        "DELETE_RECIPIENT_CLICK",
        "ADD_RECIPIENT_CLICK",
        "CUSTOM_EMAIL_SWITCH_CLICK"
      ],
      "tabName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "adobe-sign-cards-lib"
  },
  "adobeSignCardView": {
    "name": "AdobeSign Card view",
    "class": "view",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "CONNECTED",
        "DISCONNECTED",
        "OAUTH_WRONG_ACCOUNT",
        "EMPTY_STATE"
      ]
    },
    "namespace": "adobe-sign-cards-lib"
  },
  "docusignCardInteraction": {
    "name": "DocuSign Card interactions",
    "class": "interaction",
    "properties": {
      "objectTypeId": "string",
      "action": [
        "ENVELOPE_EDITOR_OPEN_CLICK",
        "ADD_FROM_EXISTING_CLICK",
        "EDIT_DRAFT_CLICK",
        "DOWNLOAD_PDF_CLICK",
        "IMPORT_SIGNED_DOCUMENTS_CLICK",
        "SHOW_ENVELOPE_HISTORY_CLICK",
        "ADD_FROM_TEMPLATE_CLICK",
        "FILTER_CHANGE",
        "EDIT_ENVELOPE_CLICK",
        "REMOVE_ENVELOPE_CLICK",
        "RECONNECT_BUTTON_CLICK"
      ]
    },
    "namespace": "docusign-cards-lib",
    "version": "1"
  },
  "docusignEnvelopeEditorInteraction": {
    "name": "DocuSign Envelope Editor interactions",
    "class": "interaction",
    "properties": {
      "action": [
        "TAB_CLICK",
        "NEXT_CLICK",
        "SAVE_CLICK",
        "SEND_ENVELOPE_CLICK",
        "EXIT_CLICK",
        "ADD_DOCUMENT_CLICK",
        "REMOVE_DOCUMENT_CLICK",
        "MORE_INFORMATION_CLICK",
        "USE_SUGGESTION_CLICK",
        "DELETE_RECIPIENT_CLICK",
        "ADD_RECIPIENT_CLICK",
        "CUSTOM_EMAIL_SWITCH_CLICK"
      ],
      "tabName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "docusign-cards-lib",
    "version": "1"
  },
  "docusignCardView": {
    "name": "DocuSign Card view",
    "class": "view",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "CONNECTED",
        "DISCONNECTED",
        "OAUTH_WRONG_ACCOUNT",
        "EMPTY_STATE"
      ]
    },
    "namespace": "docusign-cards-lib",
    "version": "1"
  },
  "activityVisualizationInteraction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "activityType": {
        "isOptional": true,
        "type": "string"
      },
      "direction": {
        "isOptional": true,
        "type": "string"
      },
      "location": "string",
      "type": {
        "isOptional": true,
        "type": "string"
      },
      "whereScreen": {
        "isOptional": true,
        "type": "string"
      },
      "value": {
        "isOptional": true,
        "type": "string"
      }
    },
    "namespace": "crm",
    "version": "1"
  },
  "clickToViewConversation": {
    "properties": {
      "action": [
        "click to view conversation"
      ],
      "channel": [
        "LIVE_CHAT (1000)",
        "FB_MESSENGER (1001)",
        "EMAIL (1002)",
        "WHATS_APP (1007)"
      ],
      "platform": {
        "type": "string"
      }
    },
    "namespace": "Conversations",
    "name": "Thread Interaction",
    "class": "interaction"
  },
  "viewFullConversation": {
    "properties": {
      "action": [
        "view full conversation",
        "view full conversation within 24hr",
        "view full conversation after 24hr"
      ],
      "channel": [
        "LIVE_CHAT (1000)",
        "FB_MESSENGER (1001)",
        "EMAIL (1002)",
        "WHATS_APP (1007)"
      ],
      "platform": {
        "type": "string"
      }
    },
    "namespace": "Conversations",
    "name": "Thread Interaction",
    "class": "interaction"
  },
  "collapseConversation": {
    "properties": {
      "action": [
        "collapse conversation"
      ],
      "channel": [
        "LIVE_CHAT (1000)",
        "FB_MESSENGER (1001)",
        "EMAIL (1002)",
        "WHATS_APP (1007)"
      ],
      "platform": {
        "type": "string"
      }
    },
    "namespace": "Conversations",
    "name": "Thread Interaction",
    "class": "interaction"
  },
  "viewConversationInInbox": {
    "properties": {
      "action": [
        "view conversation in inbox"
      ],
      "channel": [
        "LIVE_CHAT (1000)",
        "FB_MESSENGER (1001)",
        "EMAIL (1002)",
        "WHATS_APP (1007)"
      ],
      "platform": {
        "type": "string"
      }
    },
    "namespace": "Conversations",
    "name": "Thread Interaction",
    "class": "interaction"
  },
  "indexInteractions": {
    "name": "index interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "objectId": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeName": {
        "type": "string",
        "isOptional": true
      },
      "isInactive": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDealPriority": {
        "type": "boolean",
        "isOptional": true
      },
      "dealPriority": {
        "type": "string",
        "isOptional": true
      },
      "numberOfPinnedViews": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "filterUsage": {
    "name": "filter usage",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "filterInteractions": {
    "name": "filter interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "component": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "filterFamily": {
        "type": "string",
        "isOptional": true
      },
      "panelKey": {
        "type": "string",
        "isOptional": true
      },
      "maxCount": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "useLocalSearch": {
    "name": "use local search",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "createSavedView": {
    "name": "create saved view",
    "class": "usage",
    "properties": {
      "action": "string",
      "fromClone": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "createSavedForecastView": {
    "name": "create saved Forecast view",
    "class": "usage",
    "properties": {
      "action": "string",
      "fromClone": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "openForecastSubmissionPanel": {
    "name": "openForecastSubmissionPanel",
    "class": "usage",
    "properties": {
      "action": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "editSavedView": {
    "name": "edit saved view",
    "class": "usage",
    "properties": {
      "action": "string",
      "modified": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "openSavedView": {
    "name": "open saved view",
    "class": "interaction",
    "properties": {
      "action": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "exportSavedView": {
    "name": "export view",
    "class": "usage",
    "properties": {
      "exportedPropertiesNum": {
        "type": "number"
      },
      "exportType": {
        "type": [
          "selected columns",
          "all columns",
          "COLUMNS_ON_VIEW",
          "ALL_PROPERTIES_WITHOUT_ASSOCIATIONS",
          "ALL_PROPERTIES_WITH_ASSOCIATIONS"
        ]
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "movedBoardColumnItem": {
    "name": "moved board column item",
    "class": "usage",
    "properties": {
      "type": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "stageType": {
        "type": "string",
        "isOptional": true
      },
      "stageName": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "editColumns": {
    "name": "edit columns",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "objectTypeName": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "bulkAssign": {
    "name": "bulk assign",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "bulkEditRecords": {
    "name": "bulk edit records",
    "class": "usage",
    "properties": {
      "subscreen2": {
        "type": "string"
      },
      "totalCount": {
        "type": "string"
      },
      "editedProperties": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "bulkDelete": {
    "name": "bulk delete",
    "class": "usage",
    "properties": {
      "subscreen2": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "indexPageview": {
    "name": "index pageview",
    "class": "view",
    "properties": {
      "viewType": {
        "type": "string"
      },
      "objectTypeId": {
        "type": "string"
      },
      "objectTypeName": {
        "type": "string"
      },
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "numberOfPinnedViews": {
        "type": "number",
        "isOptional": true
      },
      "pinnedViewsLimit": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "saveBulkCommunicationSubscription": {
    "name": "save bulk communication subscription",
    "class": "usage",
    "properties": {
      "step": {
        "type": [
          "MODAL_OPEN",
          "FINISH_SUCCESS",
          "FINISH_FAILURE"
        ],
        "isOptional": true
      },
      "channel": {
        "type": [
          "EMAIL",
          "WHATSAPP",
          "SMS"
        ],
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "legalBasis": {
        "type": [
          "LEGITIMATE_INTEREST_PQL",
          "LEGITIMATE_INTEREST_CLIENT",
          "PERFORMANCE_OF_CONTRACT",
          "CONSENT_WITH_NOTICE",
          "NON_GDPR",
          "PROCESS_AND_STORE",
          "LEGITIMATE_INTEREST_OTHER"
        ],
        "isOptional": true
      },
      "removeFromAllEmail": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "marketingEventsOptInModal": {
    "name": "marketing events opt in modal",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "object added",
          "object rejected"
        ]
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "transcriptQuickfilter": {
    "name": "transcriptQuickfilter",
    "class": "interaction",
    "properties": {
      "action": "string",
      "componentOrigin": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "inlineEditing": {
    "name": "inline editing",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "save",
          "cancel",
          "shown unsaved changes modal",
          "navigated away with unsaved changes",
          "canceled unsaved changes modal",
          "confirmed unsaved changes modal"
        ]
      },
      "objectTypeId": {
        "type": "string"
      },
      "numberOfProperties": {
        "type": "number",
        "isOptional": true
      },
      "totalRecords": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "scheduling-interaction": {
    "name": "Scheduling interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-insert-proposed-times",
        "clicked-insert-scheduling-link",
        "inserted-scheduling-link"
      ]
    },
    "namespace": "meetings"
  },
  "use-android-app-installprompt": {
    "name": "use-android-app-installprompt",
    "class": "interaction",
    "properties": {
      "what_event_subtype": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "use-bulk-add-to-list": {
    "name": "use-bulk-add-to-list",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "use-bulk-create-tasks": {
    "name": "use-bulk-create-tasks",
    "class": "usage",
    "properties": {
      "what_event_subtype": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "use-bulk-enroll-in-sequence": {
    "name": "use-bulk-enroll-in-sequence",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "use-bulk-enroll-in-workflow": {
    "name": "use-bulk-enroll-in-workflow",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "use-bulk-remove-from-list": {
    "name": "use-bulk-remove-from-list",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "use-linked-sales-object-iframe": {
    "name": "use-linked-sales-object-iframe",
    "class": "usage",
    "properties": {
      "applicationName": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "use-sequence-timeline-edit": {
    "name": "use-sequence-timeline-edit",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "inserted-content-from-popover": {
    "name": "inserted-content-from-popover",
    "class": "interaction",
    "properties": {
      "sawPopover": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "inserted-snippet": {
    "name": "inserted-snippet",
    "class": "interaction",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "settings-interaction": {
    "name": "settings-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "app": {
        "type": "string",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "CONTACT",
          "COMPANY",
          "DEAL",
          "TASK",
          "TICKET"
        ],
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string",
        "isOptional": true
      },
      "quotasChanged": {
        "type": "number",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsInteractionWithType": {
    "name": "settings interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": "string",
      "hasWriteScope": {
        "type": "boolean",
        "isOptional": true
      },
      "modal": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsInteractionWithSuccess": {
    "name": "settings interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": {
        "type": "boolean"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "saveBoardCardCustomizationSettings": {
    "name": "Save Board Card Customization Settings",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "location": "string",
      "objectTypeId": "string",
      "properties": "array",
      "propertiesCount": "number"
    },
    "namespace": "settingsui"
  },
  "settings-email-plugin-check-retry-clicked": {
    "name": "settings-email-plugin-check-retry-clicked",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-enable-company-autocreate": {
    "name": "settings-enable-company-autocreate",
    "class": "usage",
    "properties": {
      "backfillRequired": "boolean",
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-import-export-clicked-import-button": {
    "name": "settings-import-export-clicked-import-button",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-start-company-autocreate-backfill": {
    "name": "settings-start-company-autocreate-backfill",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-bcc": {
    "name": "view-settings-bcc",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-calling": {
    "name": "view-settings-calling",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-companies": {
    "name": "view-settings-companies",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-config": {
    "name": "view-settings-config",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-contacts": {
    "name": "view-settings-contacts",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-deals": {
    "name": "view-settings-deals",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-email": {
    "name": "view-settings-email",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-importexport": {
    "name": "view-settings-importexport",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-notifications": {
    "name": "view-settings-notifications",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-privacy": {
    "name": "view-settings-privacy",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-supportForm": {
    "name": "view-settings-supportForm",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-salestools": {
    "name": "view-settings-salestools",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-tasks": {
    "name": "view-settings-tasks",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-tickets": {
    "name": "view-settings-tickets",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-pipeline-saved": {
    "name": "Deal Pipeline Saved",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-pipeline-created": {
    "name": "Deal Pipeline Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "atPipelineLimit": {
        "type": "boolean"
      },
      "pipelineLimit": {
        "type": "number"
      }
    },
    "namespace": "settingsui"
  },
  "lifecycle-stage-automation-setting-changed": {
    "name": "Lifecycle stage automation setting update",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string"
      },
      "enabled": {
        "type": "boolean"
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "lifecycle-stage-saved": {
    "name": "Lifecycle Stage Saved",
    "class": "usage",
    "properties": {
      "action": "string",
      "count": {
        "type": "number"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-pipeline-saved": {
    "name": "Ticket Pipeline Saved",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-pipeline-created": {
    "name": "Ticket Pipeline Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "atPipelineLimit": {
        "type": "boolean"
      },
      "pipelineLimit": {
        "type": "number"
      }
    },
    "namespace": "settingsui"
  },
  "deal-stage-created": {
    "name": "Deal Stage Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-stage-created": {
    "name": "Ticket Stage Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-usage": {
    "name": "Settings Usage",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "enabled": {
        "type": "boolean",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-navigation": {
    "name": "Settings Navigation",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "contact-settings-pageview": {
    "name": "Contact Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "company-settings-pageview": {
    "name": "Company Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-settings-pageview": {
    "name": "Deal Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-settings-pageview": {
    "name": "Ticket Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "import-export-pageview": {
    "name": "Import Export Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-listcreate": {
    "name": "view-listcreate",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "view-listdetail": {
    "name": "view-listdetail",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "view-listsindex": {
    "name": "view-listsindex",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createReport": {
    "name": "create report",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "list-detail"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "editListColumns": {
    "name": "edit columns",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": [
        "list-detail"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createList": {
    "name": "create list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "classicListId": {
        "isOptional": true,
        "type": "number"
      },
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "fromClone": {
        "isOptional": true,
        "type": "boolean"
      },
      "listType": [
        "static",
        "dynamic"
      ],
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "deleteList": {
    "name": "delete list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "isBulk": {
        "isOptional": true,
        "type": "boolean"
      },
      "partialSuccess": {
        "isOptional": true,
        "type": "boolean"
      },
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "updateList": {
    "name": "update list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "classicListId": {
        "isOptional": true,
        "type": "number"
      },
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "listType": [
        "static",
        "dynamic"
      ],
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "createFolder": {
    "name": "create folder",
    "class": "usage",
    "properties": {
      "screen": [
        "list-index"
      ],
      "subscreen": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "deleteFolder": {
    "name": "delete folder",
    "class": "usage",
    "properties": {
      "screen": [
        "list-index"
      ],
      "subscreen": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "moveToFolder": {
    "name": "move to folder",
    "class": "usage",
    "properties": {
      "isBulk": {
        "isOptional": true,
        "type": "boolean"
      },
      "screen": [
        "list-detail",
        "list-index"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "listsPageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "list-detail",
        "list-index",
        "unused-lists",
        "recently-deleted",
        "empty-list",
        "settings",
        "activity"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "listsInteraction": {
    "name": "lists interaction",
    "class": "interaction",
    "properties": {
      "ilsListId": {
        "isOptional": true,
        "type": "number"
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "listType": {
        "isOptional": true,
        "type": [
          "static",
          "dynamic"
        ]
      },
      "action": "string",
      "screen": {
        "isOptional": true,
        "type": [
          "list-detail",
          "list-index",
          "unused-lists",
          "manage-registration",
          "recently-deleted",
          "empty-list",
          "settings",
          "activity"
        ]
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "condition": {
        "type": "string",
        "isOptional": true
      },
      "filterFamily": {
        "type": "string",
        "isOptional": true
      },
      "panelKey": {
        "type": "string",
        "isOptional": true
      },
      "maxCount": {
        "type": "number",
        "isOptional": true
      },
      "targetListType": {
        "type": "string",
        "isOptional": true
      },
      "listId": {
        "type": "string",
        "isOptional": true
      },
      "notificationTypes": {
        "isOptional": true,
        "type": "array"
      },
      "listsExcluded": {
        "isOptional": true,
        "type": "number"
      },
      "recordsExcluded": {
        "isOptional": true,
        "type": "number"
      },
      "AIGeneratedListFilters": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "addContactsToRestrictedList": {
    "name": "lists interaction",
    "class": "interaction",
    "properties": {
      "objectTypeId": "string",
      "action": "string",
      "count": "number",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "bulkAddToList": {
    "name": "bulk add to list",
    "class": "usage",
    "properties": {
      "objectTypeId": "string",
      "screen": "string",
      "subscreen": [
        "index"
      ],
      "subscreen2": [
        "bulk-list-modal"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "lists"
  },
  "view-indexinteraction": {
    "name": "index-interaction",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "view-recordinteraction": {
    "name": "record-interaction",
    "class": "view",
    "properties": {
      "action": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "individualEmailThreadInteraction": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "bizops-interaction": {
    "name": "bizops-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "internal-crm-tracking": {
    "name": "internal-crm-tracking",
    "class": "interaction",
    "properties": {
      "cachedVersion": "string",
      "asset": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "createRecord": {
    "name": "create record",
    "class": "usage",
    "properties": {
      "type": "string",
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "createRecordActivation": {
    "name": "create first record",
    "class": "activation",
    "properties": {
      "type": [
        "contact",
        "company",
        "deal",
        "ticket"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "createEngagementActivation": {
    "name": "create first engagement",
    "class": "activation",
    "properties": {
      "type": [
        "email",
        "task",
        "call",
        "meeting",
        "note",
        "linkedin_message",
        "postal_mail",
        "sms",
        "whats_app"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "crmActivation": {
    "name": "crm activation",
    "class": "activation",
    "properties": {
      "type": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "crm-onboarding": {
    "name": "crm-onboarding",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "link": {
        "type": "string",
        "isOptional": true
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "experiment": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "crm-debug": {
    "name": "crm-debug",
    "class": "interaction",
    "properties": {
      "action": "string",
      "consumer": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "debugChunkLoadIssues": {
    "name": "crm-debug",
    "class": "interaction",
    "properties": {
      "chunk": "string",
      "error": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "crmErrorBoundary": {
    "name": "crm error boundary",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      },
      "type": {
        "type": "string",
        "isOptional": false
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "exportView": {
    "name": "index interaction",
    "class": "usage",
    "properties": {
      "action": "string",
      "exportedPropertiesNum": "number",
      "exportType": "string",
      "exportPageType": {
        "type": [
          "allViews",
          "table",
          "board"
        ]
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "emailCommunicatorInteraction": {
    "name": "record interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": "string",
      "legalBasis": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "index-interaction": {
    "name": "index-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "indexUsage": {
    "name": "index interaction",
    "class": "usage",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "inactiveCardsIsTurnedOn": {
        "type": "boolean",
        "isOptional": true
      },
      "inactiveCardsUnit": {
        "type": [
          "days",
          "weeks"
        ],
        "isOptional": true
      },
      "inactiveCardsValue": {
        "type": "number",
        "isOptional": true
      },
      "cozyCardsStyle": {
        "type": [
          "COZY",
          "COMFORTABLE"
        ],
        "isOptional": true
      },
      "cozyCardsBottomPanel": {
        "type": [
          "WITH_PANEL",
          "WITHOUT_PANEL"
        ],
        "isOptional": true
      },
      "type": {
        "type": [
          "CONTACT",
          "COMPANY",
          "DEAL",
          "TICKET",
          "TASK",
          "VISIT"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "indexPageActivation": {
    "name": "first view crm index",
    "class": "activation",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "savedFilterActivation": {
    "name": "load first saved filter",
    "class": "activation",
    "properties": {
      "type": {
        "type": [
          "contact",
          "company",
          "deal",
          "ticket",
          "visit"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "useFilters": {
    "name": "index interaction",
    "class": "usage",
    "properties": {
      "action": "string",
      "subAction": "string",
      "type": [
        "contacts",
        "companies",
        "deals",
        "tickets",
        "visits"
      ],
      "property": {
        "type": "string",
        "isOptional": true
      },
      "filter": {
        "type": "string",
        "isOptional": true
      },
      "isFirstTime": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "record-interaction": {
    "name": "record-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "label": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "isRecommended": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordUsage": {
    "name": "record interaction",
    "class": "usage",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "what_value": {
        "type": "number",
        "isOptional": true
      },
      "property_name": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "playbookInteraction": {
    "namespace": "playbooks-admin-ui",
    "name": "playbook interaction",
    "class": "interaction",
    "properties": {
      "playbookId": "number",
      "playbookType": "string",
      "action": "string",
      "engagementType": {
        "type": "string",
        "isOptional": true
      },
      "activityType": {
        "type": "string",
        "isOptional": true
      },
      "callDisposition": {
        "type": "string",
        "isOptional": true
      },
      "what_value_str": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "openIntegrationIframeInTimeline": {
    "name": "record-interaction",
    "class": "interaction",
    "properties": {
      "applicationName": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineCardInteraction": {
    "name": "record interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": "string",
      "eventType": "string",
      "type": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineCommentsUsage": {
    "name": "timeline usage",
    "class": "usage",
    "properties": {
      "action": "string",
      "activity": [
        "logged-email",
        "note",
        "logged-activity",
        "call",
        "logged-call",
        "schedule",
        "logged-meeting",
        "task",
        "meeting",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app"
      ],
      "alreadyCreatedComments": {
        "type": "number",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineUsage": {
    "name": "timeline usage",
    "class": "usage",
    "properties": {
      "action": "string",
      "activity": [
        "conversation_session",
        "email",
        "logged-email",
        "note",
        "logged-activity",
        "call",
        "logged-call",
        "schedule",
        "logged-meeting",
        "task",
        "sequence",
        "meeting",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineInteraction": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "eventType": {
        "isOptional": true,
        "type": "string"
      },
      "type": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineEmailThreadInteraction": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "engagementId": "number",
      "threadId": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineExpandCollapseInteraction": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subjectId": "string",
      "type": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "sidebarInteraction": {
    "name": "record interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "previewSidebarObject": {
    "name": "sidebar interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectTypeId": "string",
      "objectId": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "ticketsActivation": {
    "name": "tickets activation",
    "class": "activation",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "ticketsDayZero": {
    "name": "tickets day zero",
    "class": "activation",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "virality-sent-email-with-brand-banner": {
    "name": "virality",
    "class": "interaction",
    "properties": {
      "action": "string",
      "loop": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "virality-removed-brand-banner": {
    "name": "virality",
    "class": "interaction",
    "properties": {
      "action": "string",
      "loop": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "use-bulk-double-opt-in": {
    "name": "use-bulk-double-opt-in",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "createTask": {
    "name": "create task",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "task created"
        ]
      },
      "createMethod": {
        "type": [
          "manual",
          "automatic"
        ]
      },
      "createSource": {
        "type": "string",
        "isOptional": true
      },
      "reminderType": {
        "type": [
          "absolute",
          "relative"
        ],
        "isOptional": true
      },
      "relativeReminderOption": {
        "type": "number",
        "isOptional": true
      },
      "hasAssociations": {
        "type": "boolean",
        "isOptional": true
      },
      "priority": {
        "type": [
          "HIGH",
          "NONE"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "completedTask": {
    "name": "complete task",
    "class": "interaction",
    "properties": {
      "action": [
        "mark as complete",
        "bulk mark as complete"
      ],
      "version": {
        "type": [
          "control",
          "tasks-usability-beta"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "finishTaskQueueActivation": {
    "namespace": "tasks",
    "name": "finish first task queue",
    "class": "activation",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "sentFirstCrmEmail": {
    "name": "sent first crm email",
    "class": "activation",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "selectedActionsRedesignOption": {
    "name": "activity interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "opened communicator from activity redesign"
      ],
      "subAction": [
        "click from the timeline dropdown"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "communicationSubscriptionCardInteraction": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open current subscriptions",
          "open add subscription",
          "open subscription history",
          "open updated subscription panel",
          "subscription feedback",
          "subscribe action",
          "unsubscribe action",
          "gdpr note click",
          "open brand",
          "open primary",
          "open secondary",
          "open default",
          "view history"
        ]
      },
      "isHelpful": {
        "type": "boolean",
        "isOptional": true
      },
      "textFeedback": {
        "type": "string",
        "isOptional": true
      },
      "isGdprEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "isGdprEnforced": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "saved-properties": {
    "name": "saved-properties",
    "class": "interaction",
    "properties": {
      "properties": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "associateSidebarInteraction": {
    "name": "record interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "Associated objects from the associate panel",
          "Associated objects from the associate panel with flexible associations flow",
          "Created a new object from the associate panel"
        ]
      },
      "type": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineShowDetailsInteraction": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "activity": [
        "call",
        "email",
        "logged-call",
        "logged-email",
        "logged-meeting",
        "meeting",
        "note",
        "sequence",
        "task",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app",
        "integration"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineUsageDetailsInteraction": {
    "name": "timeline usage",
    "class": "usage",
    "properties": {
      "action": "string",
      "activity": [
        "call",
        "email",
        "logged-call",
        "logged-email",
        "logged-meeting",
        "meeting",
        "note",
        "sequence",
        "task",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app",
        "integration"
      ],
      "engagementId": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineEngagementInteraction": {
    "name": "timeline usage",
    "class": "usage",
    "properties": {
      "action": "string",
      "activity": [
        "call",
        "email",
        "logged-call",
        "logged-email",
        "logged-meeting",
        "meeting",
        "note",
        "sequence",
        "task",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineEditEventInteraction": {
    "name": "timeline usage",
    "class": "usage",
    "properties": {
      "action": "string",
      "activity": [
        "email",
        "note",
        "call",
        "task",
        "sequence",
        "meeting",
        "logged-call",
        "logged-email",
        "logged-meeting",
        "linkedin_message",
        "logged-linked-in-message",
        "postal_mail",
        "logged-postal-mail",
        "sms",
        "logged-sms",
        "whats-app",
        "logged-whats-app"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "pageRefresh": {
    "name": "refresh",
    "class": "interaction",
    "properties": {
      "objectType": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "propertyEditorInteraction": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open single property history sidebar",
          "open email from contact property"
        ]
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "aboutCardUsage": {
    "name": "record usage",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "add property to about card",
          "reorder about card",
          "confirm record properties account default reset"
        ]
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordHighlightInteraction": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "unbounce"
        ]
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordHighlightUsage": {
    "name": "record usage",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "edit record highlight",
          "merge objects"
        ]
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordPageView": {
    "name": "Record Pageview",
    "class": "view",
    "properties": {
      "type": "string",
      "screen": "string",
      "subscreen": {
        "isOptional": true,
        "type": "string"
      },
      "subjectId": {
        "isOptional": true,
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "pageview": {
    "name": "pageview",
    "class": "view",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "savedProperties": {
    "name": "saved properties",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "update primary in multiple emails",
          "add new secondary in multiple emails",
          "delete secondary in multiple emails",
          "update secondary in multiple emails"
        ]
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "communicatorFullScreenOpenInteractionV2": {
    "name": "activity interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "expand to full screen"
      ],
      "activity": [
        "EMAIL",
        "NOTE",
        "LOGGED-CALL",
        "LOGGED-EMAIL",
        "LOGGED-LINKED-IN-MESSAGE",
        "LOGGED-MEETING",
        "LOGGED-POSTAL-MAIL",
        "LOGGED-SMS",
        "LOGGED-WHATS-APP",
        "SCHEDULE"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineInteractions": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "timelineThreadInteractions": {
    "name": "timeline interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "threadId": "string",
      "engagementId": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "recordInteractions": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "toObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "fromObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "cardInteractions": {
    "name": "card interactions",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": {
        "type": "string",
        "isOptional": true
      },
      "objectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "toObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "fromObjectTypeId": {
        "type": "string",
        "isOptional": true
      },
      "card": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "engagementUsage": {
    "name": "create engagement",
    "class": "usage",
    "properties": {
      "activity": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "engagementInteraction": {
    "name": "create engagement",
    "class": "interaction",
    "properties": {
      "activity": "string",
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "activityCreatorInteractions": {
    "name": "activity creator interactions",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "activityCreatorReorderInteractions": {
    "name": "activity creator interactions",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "activity1": {
        "type": "string",
        "isOptional": true
      },
      "activity2": {
        "type": "string",
        "isOptional": true
      },
      "activity3": {
        "type": "string",
        "isOptional": true
      },
      "activity4": {
        "type": "string",
        "isOptional": true
      },
      "activity5": {
        "type": "string",
        "isOptional": true
      },
      "validationError": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "activityCreatorFileInteractions": {
    "name": "activity creator interactions",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "objectType": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": "string"
      },
      "fileSource": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "useMention": {
    "name": "use @mention",
    "class": "usage",
    "properties": {
      "location": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "changeTicketStatusToClosedInRecord": {
    "name": "change ticket status to closed in record",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "changeTicketStatusToOpenInRecord": {
    "name": "change ticket status to open in record",
    "class": "usage",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "deleteRecord": {
    "name": "delete record",
    "class": "usage",
    "properties": {
      "type": {
        "type": [
          "permanent",
          "with_option_to_restore"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "addAssociation": {
    "name": "add association",
    "class": "usage",
    "properties": {
      "toObjectTypeId": {
        "type": "string"
      },
      "fromObjectTypeId": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "removeAssociation": {
    "name": "remove association",
    "class": "usage",
    "properties": {
      "toObjectTypeId": {
        "type": "string"
      },
      "fromObjectTypeId": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "sidebar-interaction": {
    "name": "sidebar-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "isActive": {
        "type": "boolean",
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "processorType": {
        "type": [
          "BYO_STRIPE",
          "HS_PAYMENTS",
          "NONE"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "universalAssociationsSelect": {
    "name": "universal associations select",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string"
      },
      "toObjectTypId": {
        "type": "string",
        "isOptional": true
      },
      "engagementOrInteractionType": {
        "type": "string"
      },
      "location": {
        "type": "string"
      },
      "pluralObjectName": {
        "type": "string"
      },
      "subjectObjectTypeId": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "marketableContactsCheckboxInteraction": {
    "name": "record interactions",
    "class": "interaction",
    "properties": {
      "action": [
        "checkbox-marketing-contact"
      ],
      "isEnabled": {
        "type": "boolean"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "crm"
  },
  "sample-contact-failure": {
    "name": "sample-contact-failure",
    "class": "interaction",
    "namespace": "crm",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "interactionEvent": {
    "name": "interaction event",
    "class": "interaction",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "usageEvent": {
    "name": "usage event",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "subscriptionChange": {
    "name": "subscription change",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "delta": {
        "type": "number",
        "isOptional": true
      },
      "dateRange": {
        "type": "number",
        "isOptional": true
      },
      "updateType": {
        "type": [
          "UPGRADE",
          "DOWNGRADE",
          ""
        ],
        "isOptional": true
      },
      "discountsApplied": {
        "type": "boolean",
        "isOptional": true
      },
      "added": {
        "type": "boolean",
        "isOptional": true
      },
      "swapped": {
        "type": "boolean",
        "isOptional": true
      },
      "removed": {
        "type": "boolean",
        "isOptional": true
      },
      "proratedAdjustment": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "subscriptionUpsert": {
    "name": "subscription upsert",
    "class": "usage",
    "properties": {
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      },
      "type": {
        "type": "string"
      },
      "subType": {
        "type": "string",
        "isOptional": true
      },
      "createdAt": {
        "type": "number"
      },
      "termType": {
        "type": [
          "DAYS",
          "MONTHS",
          "QUARTERS",
          "SIX_MONTHS",
          "YEARS"
        ]
      },
      "numPayments": {
        "type": "number"
      },
      "startDate": {
        "type": "number",
        "isOptional": true
      },
      "delay": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "commerce-subscription",
    "version": "1"
  },
  "createTaskFromUI": {
    "name": "Create Task From UI",
    "class": "interaction",
    "properties": {
      "datePreset": {
        "type": [
          "today",
          "tomorrow",
          "oneBusinessDay",
          "twoBusinessDays",
          "threeBusinessDays",
          "oneWeek",
          "twoWeeks",
          "oneMonth",
          "threeMonths",
          "sixMonths",
          "CUSTOM"
        ],
        "isOptional": true
      },
      "reminderPreset": {
        "type": [
          "sameTime",
          "halfHourBefore",
          "hourBefore",
          "dayBefore",
          "weekBefore",
          "CUSTOM"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": [
          "CONTACT",
          "COMPANY",
          "DEAL",
          "TICKET",
          "CUSTOM"
        ],
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "taskQueueBarInteraction": {
    "name": "Task Queue Bar Interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "backToTasks",
          "completed",
          "rescheduled",
          "skipped",
          "skipToRemainingTask",
          "viewRemainingTasks"
        ],
        "isOptional": false
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": [
          "private",
          "shared",
          "view"
        ],
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "unassociatedTaskModalInteraction": {
    "name": "Unassociated Task Modal Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": {
        "type": [
          "viewed",
          "associationsAdded",
          "dismissed",
          "markedCompleted",
          "skipped"
        ]
      },
      "associationCount": {
        "type": "number",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "tasks"
  },
  "productPanelUsage": {
    "name": "Product panel",
    "properties": {
      "action": [
        "Create product",
        "Edit product",
        "Clone product"
      ],
      "subAction": [
        "Save",
        "Save and add another"
      ],
      "propertyName": {
        "type": "array"
      },
      "billingFrequency": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "productExport": {
    "name": "Products export",
    "properties": {
      "action": [
        "Export success"
      ],
      "subAction": [
        "Export specific objects",
        "Export all objects"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "deleteObject": {
    "properties": {
      "action": [
        "Delete product",
        "Delete folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "bulkDeleteObjects": {
    "properties": {
      "action": [
        "Bulk delete"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      }
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "modifyFolderModal": {
    "properties": {
      "action": [
        "Create folder",
        "Edit folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "moveProductToFolder": {
    "properties": {
      "action": [
        "Move product to folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "openProductOrLineItemPanel": {
    "properties": {
      "action": [
        "create",
        "edit",
        "view"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ],
      "sourceIsShopify": "boolean",
      "sourceIsEcommBridge": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "toggleFilePickerForImageURL": {
    "properties": {
      "action": [
        "Open file picker for image URL",
        "Close file picker for image URL"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "selectFileForImageURL": {
    "properties": {
      "action": [
        "Select file for image URL"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productLibraryFilter": {
    "properties": {
      "action": [
        "Filter product library"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productPaymentLinkModalInteraction": {
    "properties": {
      "action": [
        "View modal",
        "Create Payment Link"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "createObjectFromProductModal": {
    "properties": {
      "action": [
        "Create Invoice",
        "Create Payment Link",
        "Create Subscription",
        "Close modal"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productsSetup": {
    "properties": {
      "action": [
        "Navigate to manage product properties page",
        "Navigate to customize create product form page",
        "Navigate to product library",
        "Navigate to folder settings"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "editProductTiers": {
    "properties": {
      "action": [
        "Add tier",
        "Edit tier",
        "Delete tier",
        "Edit tier price",
        "Edit tier start"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "viewLineItems": {
    "properties": {
      "screen": "string",
      "sourceIsShopify": {
        "type": "boolean",
        "isOptional": true
      },
      "sourceIsEcommBridge": {
        "type": "boolean",
        "isOptional": true
      },
      "readOnly": {
        "type": "boolean"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "pageview",
    "class": "view"
  },
  "saveProductFromLineItem": {
    "properties": {
      "action": [
        "Save first product from line item",
        "Save product from line item"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "bulkSaveLineItems": {
    "properties": {
      "action": [
        "Bulk save line items"
      ],
      "numLineItemsWithTax": {
        "type": "number",
        "isOptional": true
      },
      "billingFrequency": {
        "type": "array",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateDealAmount": {
    "properties": {
      "action": [
        "Update deal amount"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateLineItemTableColumns": {
    "properties": {
      "action": [
        "Update line item table columns"
      ],
      "added": {
        "type": "array",
        "isOptional": true
      },
      "removed": {
        "type": "array",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "updateLineItemTableColumnWidth": {
    "properties": {
      "action": [
        "Update line item table column width"
      ],
      "propertyName": {
        "type": "string"
      },
      "value": {
        "type": "number"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "cancelLineItemTableColumnsUpdate": {
    "properties": {
      "action": [
        "Cancel edit columns",
        "Cancel edit columns confirmation"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems usage",
    "class": "usage"
  },
  "addLineItem": {
    "properties": {
      "action": [
        "Add line item",
        "Clone line item"
      ],
      "screen": {
        "type": "string"
      },
      "isCustom": {
        "type": "boolean",
        "isOptional": true
      },
      "subAction": {
        "type": [
          "SKU collision"
        ],
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "editLineItem": {
    "properties": {
      "action": [
        "Edit line item"
      ],
      "screen": {
        "type": "string"
      },
      "propertyName": {
        "type": "string"
      },
      "isInlineEdit": "boolean",
      "billingFrequency": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "removeLineItem": {
    "properties": {
      "action": [
        "Remove line item"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "reorderLineItem": {
    "properties": {
      "action": [
        "Reorder line item"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "selectInvalidCurrencyResolutionOption": {
    "properties": {
      "action": [
        "Select invalid currency resolution option"
      ],
      "option": [
        "product_price",
        "exchange_rate",
        "custom"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "resolveInvalidCurrencies": {
    "properties": {
      "action": [
        "Resolve line item currencies"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "cancelStagedLineItemChanges": {
    "properties": {
      "action": [
        "Cancel staged line item changes"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "goToLineItemEditorFromRecordCard": {
    "properties": {
      "action": [
        "Click line items sidebar card button"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "goToDealFromLineItemEditor": {
    "properties": {
      "action": [
        "Click deal link"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "editGlobalTerms": {
    "properties": {
      "action": [
        "Edit global terms"
      ],
      "screen": {
        "type": "string"
      },
      "propertyName": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "openGlobalTermsModal": {
    "properties": {
      "action": [
        "Open global terms modal"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "openEditColumnsModal": {
    "properties": {
      "action": [
        "Open edit columns modal"
      ],
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "clickTableCell": {
    "properties": {
      "action": [
        "Click table cell"
      ],
      "propertyName": {
        "type": "string"
      },
      "screen": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "addLineItemsPanelFilter": {
    "properties": {
      "action": [
        "Filter products",
        "Select product view",
        "Clear selected product view"
      ],
      "filterProperties": {
        "type": "array",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "clickAddLineItemFromZeroState": {
    "properties": {
      "action": [
        "Select from product library",
        "Create custom line item"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "addLineItemsPanelInteractions": {
    "properties": {
      "action": [
        "Select product",
        "Cancel add line item from product",
        "Open add line items panel"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "lineItemValidation": {
    "properties": {
      "propertyName": {
        "type": "string"
      },
      "action": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "editLineItemTiers": {
    "properties": {
      "action": [
        "Add tier",
        "Edit tier",
        "Delete tier",
        "Edit tier price",
        "Edit tier start"
      ],
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "line-items",
    "name": "LineItems interaction",
    "class": "interaction"
  },
  "communicatorInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_fileInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "fileType": {
        "type": "string"
      },
      "fileSource": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_bannerInteraction": {
    "name": "banner interaction",
    "class": "interaction",
    "properties": {
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "bannerName": {
        "type": "string",
        "isOptional": true
      },
      "cta": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicatorUsage_createFollowUpTask": {
    "name": "create followup task",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_emailActivation": {
    "name": "email activation",
    "class": "activation",
    "namespace": "communicator",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "communicator_firstEmailActivation": {
    "name": "first email activation",
    "class": "activation",
    "namespace": "communicator",
    "properties": {
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "communicator_timelineInteraction": {
    "name": "timeline interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "callType": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_formatText": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "format text"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "formatType": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_saveSignature": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "save signature"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "signatureType": {
        "type": "string"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_recipientInteraction": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Add \"to\" recipient",
        "Add \"cc\" recipient",
        "Add \"bcc\" recipient"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number"
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicator_sendEmail": {
    "name": "communicator interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "click to send email",
        "click to schedule email"
      ],
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "isNewActivityCreatorInteraction": {
        "type": "boolean",
        "isOptional": true
      },
      "emailType": "string",
      "replyAllCount": "number",
      "isReply": "boolean",
      "scheduleEmailType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  },
  "communicatorUsage_sendEmail": {
    "name": "send email",
    "class": "usage",
    "properties": {
      "emailType": "string",
      "replyAllCount": "number",
      "isReply": "boolean",
      "source": "string",
      "numberOfAttachments": "number",
      "numberOfImages": "number",
      "numberOfLinks": "number",
      "numberOfVideos": "number",
      "scheduleEmailType": {
        "type": "string",
        "isOptional": true
      },
      "senderAccountType": {
        "type": "string",
        "isOptional": true
      },
      "wordpress-plugin": {
        "type": "string",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "string",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "string",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "communicator"
  }
};